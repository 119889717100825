/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "@fontsource/suez-one"
import "@fontsource/montserrat"
const React = require("react")
const { ThemeProvider } = require("@emotion/react")

const theme = {
  heights: {
    header: "68px",
  },
}

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}
